import React, {useEffect, useState, ChangeEvent, FormEvent, Dispatch, SetStateAction, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {directionalNumbers} from "../../../assets/countryCodes";
import google from "../../../assets/Login/google.png"
import apple from "../../../assets/Login/apple.png"
import close from "../../../assets/Login/close-icon.png";
import TurnstileWidget from "../../elements/TurnstileWidget";

interface Inputs {
    phone: string;
    email: string;
    password: string;
}

interface Errors {
    phone: string;
    email: string;
    password: string;
    details: string;
    accept: string;
}
interface RegisterProps {
    setShowLogin :Dispatch<SetStateAction<boolean>> | undefined;
    setShowRegister :Dispatch<SetStateAction<boolean>> | undefined;
}
const Register: React.FC<RegisterProps> = ({ setShowRegister, setShowLogin}) => {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState<Inputs>({phone: "", email: "", password: ""});
    const [errors, setErrors] = useState<Errors>({phone: "", email: "", password: "", details: "", accept: ""});
    const [success, setSuccess] = useState<boolean>(false);
    const [checkbox, setCheckbox] = useState<boolean>(false);
    const [countryCode, setCountryCode] = useState<string>("+48");
    const [title, setTitle] = useState<string>("DogID | Rejestracja");
    const [token, setToken] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        document.title = title;
    }, [title]);

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>): void => {
        const {name, value} = e.target;
        setErrors((prevState) => ({...prevState, [name]: ""}));
        setInputs((prevState) => ({...prevState, [name]: value}));
    };

    const handleRegister = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        let error = false;
        setLoading(true);

        setErrors({phone: "", email: "", password: "", details: "", accept: ""});

        if (inputs.phone.trim() === "") {
            setErrors((prevState) => ({...prevState, phone: "Proszę wpisać poprawny numer telefonu"}));
            error = true;
            setLoading(false);
        }
        if (inputs.email.trim() === "") {
            setErrors((prevState) => ({...prevState, email: "Proszę wpisać email"}));
            error = true;
            setLoading(false);
        }
        if (!emailRegex.test(inputs.email)) {
            setErrors((prevState) => ({...prevState, email: "Proszę wpisać poprawny email"}));
            error = true;
            setLoading(false);
        }
        if (inputs.password.trim() === "") {
            setErrors((prevState) => ({...prevState, password: "Proszę wpisać hasło"}));
            error = true;
            setLoading(false);
        }
        if (!checkbox) {
            setErrors((prevState) => ({...prevState, accept: "Proszę zaakceptować regulamin"}));
            error = true;
            setLoading(false);
        }

        if (!error) {
            const toSend = {
                email: inputs.email,
                phone: `${countryCode}${inputs.phone}`,
                password: inputs.password,
            };
            const requestOptions: RequestInit = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({...toSend, recaptcha:token}),
            };

            fetch(`${process.env.REACT_APP_API_URL}/api/auth/register`, requestOptions)
                .then((response) => {
                    if (response.ok) {
                        if (setShowLogin) {
                            setShowLogin(true);
                        }
                        if (setShowRegister) {
                            setShowRegister(false);
                        }
                        setLoading(false);
                    } else {
                        return response.json();
                    }
                })
                .then((response) => {
                    setErrors({
                        phone: response.phone ? response.phone[0] : "",
                        email: response.email ? response.email[0] : "",
                        password: "",
                        details: "",
                        accept: "",
                    });
                    setLoading(false);
                })
                .catch(() => {
                    turnstileRef.current?.reset();
                    setLoading(false);
                })
        }
    };
    const turnstileRef = useRef<{ reset: () => void }>(null);
    const handleOnSuccess = (token: string) => {
        setToken(token);
    }
    return (
        <div className="register-container">
                <img src={close} className='close-icon' alt="close icon" onClick={() => setShowRegister && setShowRegister(false)}/>
            <div className="register-column">
                <h1 className="register-title">Witaj</h1>
                <h1 className="register-title-mobile">Witaj! 👋</h1>
                <p className='register-text'>Cieszymy się, że chcesz dołączyć do naszej społeczności dbającej o
                    bezpieczeństwo i komfort Twojego pupila.</p>
                <form className="register-form" onSubmit={handleRegister}>
                    <div className="contact-number">
                        <select
                            className={`global-input country-code ${errors.phone && 'error'}`}
                            name="country"
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                        >
                            {directionalNumbers.map((number, index) => (
                                <option key={index} value={number}>
                                    {number}
                                </option>
                            ))}
                        </select>
                        <input
                            className={`global-input phone-number ${errors.phone && 'error'}`}
                            placeholder="Numer kontaktowy *"
                            type="text"
                            name="phone"
                            value={inputs.phone}
                            onChange={handleChangeInput}
                        />
                    </div>
                    {errors.phone && <span className="error">{errors.phone}</span>}
                    <input
                        className={`global-input ${errors.email && 'error'}`}
                        placeholder="Adres Email *"
                        type="email"
                        name="email"
                        value={inputs.email}
                        onChange={handleChangeInput}
                        autoComplete="off" // Wyłącza autofill
                    />
                    {errors.email && <span className="error">{errors.email}</span>}
                    <input
                        className={`global-input ${errors.password && 'error'}`}
                        placeholder="Hasło *"
                        type="password"
                        name="password"
                        value={inputs.password}
                        onChange={handleChangeInput}
                        autoComplete="off" // Wyłącza autofill
                    />
                    {errors.password && <span className="error">{errors.password}</span>}
                    <div className="policy">
                        <label className="policy-label">
                            <input
                                className={`policy-checkbox ${errors.accept && 'error'}`}
                                type="checkbox"
                                checked={checkbox}
                                onChange={() => {
                                    setCheckbox(!checkbox);
                                    setErrors((prevState) => ({...prevState, accept: ""}));
                                }}
                            />
                            <span className='label-text'>
                                Zapoznałem/am się i akceptuję
                                <a href='/regulamin'>{' '}Regulamin{' '}</a>
                                oraz
                                <a href='/polityka'>{' '}Politykę Prywatności</a>
							</span>

                        </label>
                        {errors.accept && <span className="error">{errors.accept}</span>}
                    </div>
                    <TurnstileWidget ref={turnstileRef} siteKey={process.env.REACT_APP_CLOUDFLARE_SITE_KEY} onVerify={handleOnSuccess} theme='light' action='register'/>
                    <button className='register-button' type={'submit'}>
                        { loading ? <div className="register-loader"></div> : "Zakładam konto" }
                    </button>
                    {errors.details && <span className="error">{errors.details}</span>}
                    {success && (
                        <span className="error success">
						  Pomyślnie założono konto, proszę się zalogować
						</span>
                    )}
                </form>
                <span className='have-an-account'>
                    Masz już konto?
                    <span onClick={() => {
                        setShowRegister && setShowRegister(false);
                        setShowLogin && setShowLogin(true)
                    }} className='login-link'>{' '}Zaloguj się</span>
                </span>
                <span className='checkout-our-app'>Wypróbuj naszą aplikację mobilną!</span>
                <div className='apps-container'>
                    <a href="https://apps.apple.com/pl/app/dogid/id1555738749">
                    <img src={apple} alt=""/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=eu.lightcode.dog_id&hl=en">
                        <img src={google} alt=""/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Register;
