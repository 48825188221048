const RoutesPaths = {
	Home: {
		path: "/",
		route: () => "/",
	},
	Register: {
		path: "/rejestracja",
		route: () => "/",
	},
	Login: {
		path: "/login",
		route: () => "/",
	},
	MyData: {
		path: "/moje-dane",
		route: () => "/",
	},
	MyIDs: {
		path: "/moje-identyfikatory",
		route: () => "/",
	},
	AddID: {
		path: "/dodaj-identyfikator",
		route: () => "/",
	},
	DogProfile: {
		path: "/profil-psa",
		route: () => "/",
	},
	RegisterID: {
		path: "/zarejestruj-identyfikator",
		route: () => "/",
	},
	Success: {
		path: "/register-success",
		route: () => "/",
	},
	Payment: {
		path: "/platnosci",
		route: () => "/",
	},
	Vet: {
		path: "/dla-weterynarza",
		route: () => "/",
	},
	Owner: {
		path: "/dla-wlasciciela",
		route: () => "/",
	},
	About: {
		path: "/about",
		route: () => "/",
	},
	Policy: {
		path: "/polityka",
	},
	Rules:{
		path: "/regulamin",
	},
	Contact:{
		path: "/kontakt",
	}

};

export default RoutesPaths;
