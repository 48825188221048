import React from 'react'
// @ts-ignore
import AppStore from '../../../assets/footericons/apple-app-store-icon.png'
// @ts-ignore
import GooglePlay from '../../../assets/footericons/google-play-icon.png'
import { useNavigate } from 'react-router-dom'
const Footer: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className='footer-container'>
            <div className='footer-wrapper'>
                <div className='footer-left'>
                    <div className='footer-column'>
                    <span className='logo' onClick={() => navigate('/')}>
                        DOG<span className='highlight'>id</span>
                    </span>
                        <span className='footer-text footer-address'>
                        DOGid <br/>
                        Niemcewicza 26 <br/>
                        71-520 Szczecin
                    </span>
                    </div>
                    <div className='footer-column'>
                        <span className='footer-text'>
                            <a className='link' href="/regulamin">Regulamin serwisu</a>
                        </span>
                        <span className='footer-text'>
                            <a className='link' href="/polityka" >Polityka prywatności</a>
                        </span>
                    </div>
                    <div className='footer-column call-us'>
                        <span className='footer-text'>
                           Znalazłeś psa?
                        </span>
                        <span className='footer-text'>
                            <a className='link number' href="tel:914222222">914 222 222</a>
                        </span>
                    </div>
                </div>
                <div className='footer-right'>
                    <div className='footer-column'>
                        <a href="https://apps.apple.com/pl/app/dogid/id1555738749"  target="_blank">
                            <img className='platform-image' src={AppStore} alt='image shows app store logo'/>
                        </a>

                    </div>
                    <div className='footer-column'>
                        <a href="https://play.google.com/store/apps/details?id=eu.lightcode.dog_id&hl=en"  target="_blank">
                            <img className='platform-image google' src={GooglePlay}
                                 alt='image show google play store image'/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer
