import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';

interface TurnstileWidgetProps {
    siteKey: string | undefined;
    onVerify: (token: string) => void;
    action?: string;
    cData?: string;
    theme?: 'auto' | 'dark' | 'light';
    size?: 'normal' | 'invisible' | 'compact';
    tabIndex?: number;
}

const TurnstileWidget = forwardRef(({
                                        siteKey,
                                        onVerify,
                                        action,
                                        cData,
                                        theme = 'auto',
                                        size = 'normal',
                                        tabIndex = 0,
                                    }: TurnstileWidgetProps, ref) => {
    const widgetRef = useRef<HTMLDivElement>(null);
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useImperativeHandle(ref, () => ({
        reset: () => {
            if (widgetRef.current && (window as any).turnstile) {
                (window as any).turnstile.reset(widgetRef.current);
            }
        }
    }));

    useEffect(() => {
        if ((window as any).turnstile) {
            setScriptLoaded(true);
            return;
        }

        const script = document.createElement('script');
        script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=turnstileOnLoad';
        script.async = true;
        script.id = 'turnstile-script';

        (window as any).turnstileOnLoad = () => {
            setScriptLoaded(true);
        };

        document.head.appendChild(script);

        return () => {
            const existingScript = document.getElementById('turnstile-script');
            if (existingScript) {
                existingScript.remove();
            }
            delete (window as any).turnstileOnLoad;
        };
    }, []);

    useEffect(() => {
        if (scriptLoaded && widgetRef.current) {
            if ((window as any).turnstile && !(widgetRef.current.getAttribute('data-rendered'))) {
                (window as any).turnstile.render(widgetRef.current, {
                    sitekey: siteKey,
                    callback: (token: string) => {
                        onVerify(token);
                    },
                    'error-callback': () => {
                        console.error('Turnstile error occurred.');
                    },
                    action,
                    cData,
                    theme,
                    size,
                    tabindex: tabIndex,
                });
                widgetRef.current.setAttribute('data-rendered', 'true');
            }
        }
    }, [scriptLoaded, siteKey, onVerify, action, cData, theme, size, tabIndex]);

    return <div ref={widgetRef} style={{ width: '100%', height: 'auto', display:'flex', justifyContent:'center' }} />;
});

export default TurnstileWidget;
